import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { AppContext, clsNames, date, dConfirm, fromNow, get, isEmpty, tSuccess, useUser, version } from 'unno-comutils'
import { logout } from 'unno-comutils/connect'

import { MenuAcp, Menus } from './service/menu'
import { Icon, PageScroll } from 'unno-comutils/ui'
import Tooltip from 'unno-comutils/Tooltip'

import Dropdown from 'unno-comutils/Dropdown'
import { AlertPing } from './components/common'
//import { logout } from './components/test/Connect'

export default function Layout (props: any) {
    const location = useLocation()

    const path = location.pathname + '/'
    const noMenu = path.startsWith('/view/')
    const menu = !noMenu && Menus.find(m => path.startsWith('/' + m.page))

    return <div className={clsNames('layout', menu && '-' + menu.color)}>
        {!noMenu && <MenuSide/>}
        <div className="layout-content">
            <div className={'_protect'}>
                {props.children}
            </div>
        </div>
    </div>
}

const getFavMenu = () => (localStorage.getItem('menu_fav') || 'core|home,core|support').split(',').filter(Boolean) || []

const matchMenu = (menu: any, cPath: string) => (menu.urlMatch && cPath.startsWith(menu.urlMatch + '/')) || cPath.startsWith(menu.url + '/')

const itemUrl = (m: any) => m.url

function MenuSide (props: any) {
    const user = useUser()
    const location = useLocation()
    const history = useHistory()

    const [miniSideMenu, setMiniSideMenu] = useState(false)
    const [fav, setFav] = useState(getFavMenu())

    const userLogout = (setUser: any) => {
        dConfirm('Logout ?').then(() => {
            logout().then(() => {
                tSuccess('ออกจากระบบแล้ว')
                setUser(null)
            })
        })
    }

    const onMiniSideMenu = () => {
        const mini = !miniSideMenu
        setMiniSideMenu(mini)
        localStorage.setItem('SIDEMENU_MINI', mini ? '1' : '')
    }

    // ----- MEMO

    useEffect(() => {
        setMiniSideMenu(localStorage.getItem('SIDEMENU_MINI') === '1')
    }, [])

    const menus = useMemo(() => {
        const menus: any = []
        Menus.forEach((m1: any) => {
            if (isEmpty(m1.role) || user.allow(m1.role)) {
                m1.items.forEach((m2: any) => {
                    m2.items.forEach((m3: any) => {
                        if ((isEmpty(m3.role) || user.allow(m3.role)) && fav.some(n => n === [m1.page, m3.page].join('|'))) {
                            menus.push({ ...m3, color: m1.color })
                        }
                    })
                })
            }
        })
        return menus
    }, [user, fav])

    const cPath = useMemo(() => location.pathname + '/', [location])

    // ----- RENDER

    const onClickItem = (m: any, e: any) => {
        if (!e.ctrlKey) {
            e.preventDefault()
            history.push(m.url)
        }
    }

    // ----- RENDER

    return <div className={clsNames('layout-sidemenu', props.grow && ' -grow', miniSideMenu && ' -mini')}>
        <div className="layout-mainmenu">
            <MenuMain onUpdate={setFav}/>
            <div className="_user">
                {user.admin && <Icon name="hat-wizard" button onClick={() => history.push('/acp')}/>}
                <AppContext.Consumer>
                    {({ setUser }) => <Icon name={'power-off'} className={'white ml-2'} button lg onClick={() => userLogout(setUser)}/>}
                </AppContext.Consumer>
            </div>
        </div>
        <div className="layout-menuifo">
            <span className={'_version'}>v{process.env.REACT_APP_VERSION} ~ s{version}</span>
            <span className={'_user'}>{user && user.name}</span>
        </div>
        <div className="layout-menu">
            {cPath.startsWith('/acp') && <MenuExtra path={cPath} onClickItem={onClickItem}/>}
            <PageScroll container>
                {menus.map((m: any) => {
                    const cls = clsNames('layout-menu-item', '-' + m.color, matchMenu(m, cPath) && '-active')
                    const item = <a key={'m_' + m.name} className={cls} href={itemUrl(m)} onClick={e => onClickItem(m, e)}>
                        <Icon name={m.icon} className="_icon"/>
                        <span className="_text">{m.name}</span>
                    </a>
                    return miniSideMenu ? <Tooltip key={'m_' + m.name} title={m.name} position={'r'}>{item}</Tooltip> : item
                })}
            </PageScroll>
            <div className="my-2">
                <Dropdown className="popup-notify un-scroll" up right button={<div className="layout-menu-item -extar">
                    {user.notify && <AlertPing/>}
                    <Icon name={'bell'}/>
                    <span className="_text">แจ้งเตือน</span>
                </div>}><PopupNotify/></Dropdown>
            </div>
            <div className="btn-coll mb-3"><Icon button name={'chevron-double-' + (miniSideMenu ? 'right' : 'left')} onClick={onMiniSideMenu}/></div>
        </div>
    </div>
}

function MenuMain (props: any) {
    const location = useLocation()
    const user = useUser()

    const [dmenu, setDMenu] = useState(false)
    const [fav, setFav] = useState(getFavMenu())

    const onClickFav = (page: any, e: any) => {
        e.stopPropagation()
        toggleFavMenu(page)
    }

    const toggleFavMenu = (name: string) => {
        const saveMenu = getFavMenu()
        if (saveMenu.indexOf(name) >= 0) {
            localStorage.setItem('menu_fav', saveMenu.filter((m: any) => m !== name).join(','))
        }
        else {
            saveMenu.push(name)
            localStorage.setItem('menu_fav', saveMenu.join(','))
        }
        const gfav = getFavMenu()
        setFav(gfav)
        props.onUpdate(gfav)
    }

    const menus = useMemo(() => Menus.filter((m1: any) => isEmpty(m1.role) || user.allow(m1.role)).map((m1: any) => {
        const items = m1.items.map((m2: any) => {
            const items = m2.items.filter((m3: any) => isEmpty(m3.role) || user.allow(m3.role))
            return items.length === 0 ? false : { ...m2, items }
        }).filter(Boolean) || []
        return items.length === 0 ? false : { ...m1, items }
    }).filter(Boolean), [user])

    useEffect(() => {
        setDMenu(false)
    }, [location.pathname])

    return <Dropdown show={dmenu} onToggle={(d: any) => setDMenu(d)} className={'menumain-dropdown un-scroll'}
                     button={<div className="layout-mainmenu-btn"><Icon name={'bars'} solid className="menumain-btn"/></div>}>
        {menus.map((m: any) => <MenuMainGroup key={'x_' + m.page} menu={m} fav={fav} onClickFav={onClickFav}/>)}
    </Dropdown>

}

export function MenuExtra (props: any) {
    const menus = useMemo(() => {
        if (props.path.startsWith('/acp')) return MenuAcp
        return false
    }, [props.path])

    if (!menus) return null

    return <div className="layout-menu-extra">{menus.map((m: any) => {
        return <a key={'m_' + m.name} className={clsNames('layout-menu-item', matchMenu(m, props.path) && '-active')} href={itemUrl(m)}
                  onClick={e => props.onClickItem(m, e)}>
            <Icon name={m.icon} className="_icon"/>
            <span className="_text">{m.name}</span>
        </a>
    })}</div>
}

export function MenuMainGroup (props: any) {
    const { menu, fav, onClickFav } = props

    if (menu.page === 'core') {
        return <div className="menumain-group-core">
            {menu.items.map((m1: any, sx: any) => <div key={'sx_' + menu.page + '_' + sx} className="menumain-group_item">
                {m1.items.map((m2: any) => <MenuMainItem key={'x_' + m2.page} menu={m2} group={menu.page} fav={fav} onClickFav={onClickFav}/>)}
            </div>)}
        </div>
    }

    return <div className="menumain-group">
        <div className="menumain-group_header"><span>{menu.name}</span></div>
        {menu.items.map((m1: any, sx: any) => <div key={'sx_' + menu.page + '_' + sx} className="menumain-group_item">
            <div className="menumain-item_label">{m1.name}</div>
            <div className="menumain-items">{m1.items.map((m2: any) => <MenuMainItem key={'x_' + m2.page} menu={m2} group={menu.page} fav={fav} onClickFav={onClickFav}/>)}</div>
        </div>)}
    </div>
}

export function MenuMainItem (props: any) {
    const { menu, group, fav, onClickFav } = props

    const location = useLocation()
    const history = useHistory()

    const matchMenu = (menu: any, cPath: string) => (menu.urlMatch && cPath.startsWith(menu.urlMatch + '/')) || cPath.startsWith(menu.url + '/')
    const itemUrl = (m: any) => m.url

    const onClickItem = (m: any, e: any) => {
        if (!e.ctrlKey) {
            e.preventDefault()
            history.push(m.url)
        }
    }

    const cPath = useMemo(() => location.pathname + '/', [location])
    const fk = [group, menu.page].join('|')

    return <div className={clsNames('menumain-item', matchMenu(menu, cPath) && '-active')}>
        <a href={itemUrl(menu)} className="_link" onClick={e => onClickItem(menu, e)}>
            <Icon name={menu.icon} className="_icon" solid/>
            <span className="_text">{menu.name}</span>
        </a>
        <Icon name={'star'} className={clsNames('_icon-fav', fav.some((n: any) => n === fk) && '-active')} onClick={e => onClickFav(fk, e)}/>
    </div>
}

function PopupNotify (props: any) {
    const history = useHistory()
    const [datas, setDatas] = useState<any>([])

    // ----- ACTION

    const loadNotify = () => {
        get('app/notify').then((d: any) => {
            if (d.ok) {
                setDatas(d.datas)
            }
        })
    }

    // ----- EVENT

    const onGo = (url: string) => {
        //history.push(url)
    }

    // ----- MEMO

    useEffect(() => {
        loadNotify()
    }, [])

    // ----- RENDER

    return <div className={'notify-list'}>
        {datas.length > 0 ? datas.map((d: any, x: number) => <div key={'i' + x} className={'notify-item'} onClick={() => onGo(d.url)}>
            <div className={'_text'}>{d.text}</div>
            <div className={'_time'}>{date(d.time, 'ST')} ({fromNow(d.time)})</div>
        </div>) : <div className="notify-empty">ไม่มีการแจ้งเตือน</div>}
    </div>
}
